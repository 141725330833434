import moment from "moment";
import { Helmet } from "react-helmet";
import React, { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import CustomSideBar from "./components/CustomSideBar.js";


const LoginPage = lazy(() => import("./pages/auth/login"));
const Signup = lazy(() => import("./pages/auth/signup"));
const NotAuth = lazy(() => import("./pages/NotAuth/NotAuth"));
const PaymentsNew = lazy(() => import("./pages/paymentsV4/indexV2")); 
const ProfitabilityV4 = lazy(() => import("./pages/profitv4/indexV4")); 
const InvoiceTable = lazy(() => import("./pages/Invoice/InvoiceTable/index.js")); 
const InvoiceSummaryAmazon = lazy(() => import("./pages/Invoice/InvoiceSummaryAmazon/index.js")); 
const InvoiceSummaryFlipkart = lazy(() => import("./pages/Invoice/InvoiceSummaryFlipkart/index.js")); 
const ReviewsV6 = lazy(() => import("./pages/ReviewV6/index"));
const AllExport = lazy(() => import("./pages/ModuleExport"));
const SyncDetails = lazy(() =>
  import("./pages/adminSyncDetails/adminSyncDetails.js")
);
const CustomerDetails = lazy(() =>
  import("./pages/AdminDashboard/adminDashboard.js")
);
const AccountSettings = lazy(() => import("./userSettings/UserSettings.js"));
const ReturnsNew = lazy(() => import("./pages/ReturnsNew"));  
const QrCodeScanner = lazy(() =>
  import("./pages/QRCodeScanner/qrCodeScanner.js")
);
const ForgotPassword = lazy(() => import("./pages/auth/forgot"));
const Otp = lazy(() => import("./pages/auth/otp"));
const ChangePassword = lazy(() => import("./pages/auth/changePassword"));
const CreateCampaign = lazy(() =>
  import("./pages/ReviewV6/Campaigns/CreateCampaign")
);
const EditCampaign = lazy(() =>
  import("./pages/ReviewV6/Campaigns/EditCampaign")
);
const PageNotFound = lazy(() => import("./pages/PageNotFound/pageNotFound"));
const KeywordIndex = lazy(() => import("./pages/keywordTrack"));
const BuyboxSummary = lazy(() => import("./pages/BuyboxSummary/index"));
const MasterSKU = lazy(() => import("./pages/MasterSku"));
const PriceTracker = lazy(() => import("./pages/PriceTracker/index"));
const InventoryNew = lazy(() => import("./pages/InventoryV2/index"));
const Cashback = lazy(() => import("./pages/profitCashback"));
const OtherExpenses = lazy(() => import("./pages/otherExpenses"));
const OptimizeCash = lazy(() => import("./pages/ProfitCashflow"));
const PriceTrackerNew = lazy(() => import("./pages/PriceTrackerNew/index"));
const OutstandingPayments = lazy(() => import("./pages/outstandingPayments/index.js"));
const ExceptionRulesV2 = lazy(() => import("./pages/ExceptionRulesV2/index.js"));

const AddExceptionRules = lazy(() => import("./pages/AddingExceptionRules/index.js"));
const Habanaro = lazy(() => import("./pages/habanaro/index.js"));
const UploadFileStatus = lazy(() => import("./pages/UploadStatus/index.js"));
const ProfitMonth = lazy(() => import("./pages/ProfitMonthToDay/index.js"));
const Claims = lazy(() => import("./pages/Claims"));
const ProfitMonthlyComparison = lazy(() => import("./pages/ProfitMonthComparison/index.js"));
const BuyboxMultiplePincode = lazy(() => import("./pages/BuyboxMultiplePincode/index.js"));
const ProductCostInputIndex = lazy(() => import("./pages/ProductCostInput/index.js"));
const CustomDashboard = lazy(() => import("./pages/CustomDashboard/index.js"));
const ProfitDoDAnalysis = lazy(() => import("./pages/Profit DoD Analysis/index.js"));
const AmazonVCPRecon = lazy(() => import("./pages/AmazonVCPRecon/index.js"));
const AmazonStarComparison = lazy(() => import("./pages/AmazonStarPercentComparison/index.js"));



const AuthRoute = () => {
  if (!localStorage.getItem("token")) return <Navigate to="/login" />;

  if (!userPermissions()) return <Navigate to='/not-auth' />;

  if (renewplanFunction()) return <Navigate to="/account-settings" />;

  return <Outlet />;
};


const userPermissions = () => {
  let dataArray = JSON.parse(localStorage.getItem("userPermission"));
  let baseRoutes = [
    "ADMINDASHBOARD_READ",
    "PROFITABILITY_READ",
    "SYNCDETAILS_READ",
    "DASHBOARD_READ",
    "PAYMENTTRACKER_READ",
    "PROFITABILITY_READ",
    "SALESDEFENCE_READ",
    "INVENTORY_READ",
    "MASTERSKUMAPPING_READ",
    "STORAGELOCATIONS_READ",
    "ORDERSTRACKER_READ",
    "REVIEWS_READ",
    "PRODUCTCONFIG_READ",
    "ACCOUNTSETTINGS_READ",
    "RETURNSTRACKER_READ",
    "DOWNLOADS_READ",
    "ACTIONREQUIRED_READ",
   "OPTIMIZECASH_READ",
   "PROFITLOSSCOMPARISON_READ",
   "PROFITLOSSMONTHLY_READ",

  ];
  let pathName = (
    window.location.pathname.replace(/[^\w\s]|[\d]/gi, "") + "_read"
  ).toUpperCase();  
  // only for account settings----------------------     
  let pathSegments = window.location.pathname.split('/');
  let filteredSegment = pathSegments.filter(segment => segment === "account-settings")[0];
  let accountpathName = filteredSegment ? filteredSegment.replace(/[^\w\s]|[\d]/gi, "").toUpperCase() + "_READ" : "";    
  // ----------------------------------------------------------------------------        
  for (let baseRoute of baseRoutes) {
    if (pathName.startsWith(baseRoute) && dataArray.includes(baseRoute)) {
      return true;
    }
    if (accountpathName.startsWith(baseRoute) && dataArray.includes(baseRoute)) {
      return true;
    }
  }
};

const renewplanFunction = () => {
  const days = localStorage.getItem("subscriptionDays");
  const start = new Date();
  const end = moment(days, "YYYY-MM-DD");
  const subscriptiondays = Math.round((end - start) / 1000 / 60 / 60 / 24) + 2;
  return subscriptiondays <= 0;
};

const renderRoutes = (path, component) => (
  <Route element={<AuthRoute />}>
    <Route path={path} element={component} />
  </Route>
);
const createRoute = (path, element) => <Route path={path} element={element} />;
const WithCustomSideBar = ({ children }) => <CustomSideBar>{children}</CustomSideBar>;

const RoutesPage = (props) => {
  return (
    <Router {...props}>
      <Suspense
        fallback={
          <>
            <Helmet>
              <title>{"Forcesight"}</title>
            </Helmet>
            <CustomSideBar ></CustomSideBar>
          </>
        }
      >
          {/* Auth Route   */}

          {/* Excluded Customsidebar */}
        <Routes>
          {renderRoutes("/admin-dashboard", <CustomerDetails />)}
          {renderRoutes("/sync-details", <SyncDetails />)}
          {createRoute("/", <LoginPage />)}
          {createRoute("/login", <LoginPage />)}
          {createRoute("/register", <Signup />)}
          {createRoute("/forgot-password", <ForgotPassword />)}
          {createRoute("/enter-otp", <Otp {...props} />)}
          {createRoute("/change-password", <ChangePassword {...props} />)}
          {createRoute("/*", <PageNotFound />)}
          {createRoute("/not-auth",<WithCustomSideBar><NotAuth /></WithCustomSideBar> )}
          {createRoute("/create-campaigns",<CreateCampaign />)}
          {createRoute("/edit-campaigns/:id", <EditCampaign />)}
       
          

    
          {renderRoutes("/payment-tracker/:tabIndex",<WithCustomSideBar><PaymentsNew /></WithCustomSideBar> )}
          {renderRoutes("/profitability/:tabIndex",<WithCustomSideBar><ProfitabilityV4 /></WithCustomSideBar> )}
          {renderRoutes("/reviews/:tabIndex",<WithCustomSideBar><ReviewsV6 /></WithCustomSideBar> )}
          {renderRoutes("/sales-defence/:tabIndex",<WithCustomSideBar><BuyboxSummary /></WithCustomSideBar> )}
          {renderRoutes("/downloads",<WithCustomSideBar><AllExport /></WithCustomSideBar> )}
          {renderRoutes("/inventory/:tabIndex",<WithCustomSideBar><InventoryNew /></WithCustomSideBar> )}
        
          {/* ---------------------------------- */}
          
         
          {createRoute("/keywords-tracker/:tabIndex",<WithCustomSideBar><KeywordIndex /></WithCustomSideBar> )}
          {renderRoutes("/account-settings/*",<WithCustomSideBar><AccountSettings /></WithCustomSideBar> )}
          {createRoute("/return-insights/:tabIndex",<WithCustomSideBar><ReturnsNew /></WithCustomSideBar> )}
          {createRoute("/barcode-scanner", <WithCustomSideBar><QrCodeScanner /></WithCustomSideBar>)}
      
      
        
          {createRoute("/price-tracker/:tabIndex",<WithCustomSideBar> <PriceTracker /></WithCustomSideBar>)}
          
          {createRoute("/buybox-summary", <WithCustomSideBar><BuyboxSummary /></WithCustomSideBar>)}
          {renderRoutes("/productconfig/:tabIndex", <WithCustomSideBar><MasterSKU /></WithCustomSideBar>)}
          {createRoute("/cashback", <WithCustomSideBar><Cashback /></WithCustomSideBar>)}
          {createRoute("/paymentsNew",<WithCustomSideBar><PaymentsNew /></WithCustomSideBar> )}
          {createRoute("/other-expenses", <WithCustomSideBar><OtherExpenses /></WithCustomSideBar>)}
         
          
          
      


          {renderRoutes("/action-required",   <WithCustomSideBar> <ExceptionRulesV2 /></WithCustomSideBar>)}
          {renderRoutes("/optimize-cash", <WithCustomSideBar><OptimizeCash /></WithCustomSideBar>)}
         
         
          {createRoute("/invoice-table", <WithCustomSideBar><InvoiceTable /></WithCustomSideBar>)}
          {createRoute("/invoice-summary-Amazon-India",<WithCustomSideBar><InvoiceSummaryAmazon /></WithCustomSideBar> )}
          {createRoute("/invoice-summary-Flipkart", <WithCustomSideBar><InvoiceSummaryFlipkart /></WithCustomSideBar>)}
          {createRoute("/PricetrackerV2",<WithCustomSideBar><PriceTrackerNew /></WithCustomSideBar> )}
          {createRoute("/outstanding-payments", <WithCustomSideBar><OutstandingPayments /></WithCustomSideBar>)}
          {/* {renderRoutes("/exception-rules",<WithCustomSideBar><ExceptionRulesV2 /></WithCustomSideBar> )} */}
          {/* {createRoute("/exception-rules-v2", <WithCustomSideBar><ExceptionRulesV2 /></WithCustomSideBar>)} */}

          {createRoute("/add-exception-rules",<WithCustomSideBar> <AddExceptionRules /></WithCustomSideBar>)}
          {createRoute("/habaneo",<WithCustomSideBar><Habanaro /></WithCustomSideBar> )}
          {createRoute("/upload-file-status",<WithCustomSideBar><UploadFileStatus /></WithCustomSideBar> )}
          {renderRoutes("/profit-loss-monthly",<WithCustomSideBar><ProfitMonth /></WithCustomSideBar> )}
          {createRoute("/claims",<WithCustomSideBar><Claims /></WithCustomSideBar> )}
          {renderRoutes("/profit-loss-comparison",<WithCustomSideBar><ProfitMonthlyComparison /></WithCustomSideBar> )}
          {createRoute("/buybox-multiple-pincode",<WithCustomSideBar><BuyboxMultiplePincode /></WithCustomSideBar> )}
          {createRoute("/inputcost",<WithCustomSideBar><ProductCostInputIndex /></WithCustomSideBar> )}
          {createRoute("/custom-dashboard",<WithCustomSideBar><CustomDashboard /></WithCustomSideBar> )}
          {createRoute("/profit-analysis",<WithCustomSideBar><ProfitDoDAnalysis /></WithCustomSideBar> )}
          {createRoute("/amazonvcp-recon",<WithCustomSideBar><AmazonVCPRecon /></WithCustomSideBar> )}
          {createRoute("/amazon-india-star-comparison",<WithCustomSideBar><AmazonStarComparison /></WithCustomSideBar> )}


          




        </Routes>
         
      </Suspense>
    </Router>

  );
};
export default RoutesPage;
